.main-action {
  color: white;
  background-color: #d21770;
  border: 1px solid #d21770;
  width: 180px!important;
  padding-left: 24px!important;
  padding-right: 24px!important;
  padding-top: 12px!important;
  padding-bottom: 12px!important;
  border-radius: 4px!important;
  text-decoration: none!important;
  text-transform: uppercase;
  text-align: center;
  font: 1rem "gilroy-bold", sans-serif;

  &:hover {
    color: #ffffff;
  }
}

.joinus{
  color: #E80C7A !important;
  background-color: white;
  border: 1px solid #E80C7A;
  padding-left: 24px!important;
  padding-right: 24px!important;
  padding-top: 12px!important;
  padding-bottom: 12px!important;
  border-radius: 4px !important;
  text-decoration: none !important;
  text-align: center;
  margin: 0px 20px;
  font: 1rem "gilroy-bold", sans-serif;
}

@font-face {
    font-family: 'gilroy-bold';
    src: url("../fonts/bold.ttf") format("truetype");
    font-style: normal; }
  
  @font-face {
    font-family: 'gilroy-medium';
    src: url("../fonts/medium.ttf") format("truetype");
    font-style: normal; }
  
  @font-face {
    font-family: 'gilroy-regular';
    src: url("../fonts/regular.ttf") format("truetype");
    font-style: normal; }
  
  @font-face {
    font-family: 'gilroy-semi';
    src: url("../fonts/semibold.ttf") format("truetype");
    font-style: normal; }
@import 'fonts';
@import 'btn';
@import 'components/small-separator';

body {
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    -webkit-font-smoothing: antialiased;
    font-size: 1em;
    background: white;
    font-family: "gilroy-regular", sans-serif;
    color: #3a3a3a;
}

.color-pink {
    color:#cc0e63;
}

.navigation {
    padding: 15px 2.5% 15px 2.5%;
    width: 100%;
    background-color: white;

    @media screen and (max-width: 576px) {
        padding: 20px;
    }

    .navbar-nav {
        justify-content: flex-end;
        width: 100%;

        .nav-link {
            font-weight: 500;
            font-size: 16px;
            color: #191919;
            padding: 1rem;
        }
    }
}

.content-wrapper {
    margin: 0px auto;
    padding: 0px;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    float: none;

    .sections {
        width: 100%;
        z-index: 1;

        .section-content {
            padding: 0px 15% 0px 15%;
            z-index: 9999;
            display: flex;
            flex-direction: column;

            .content-wrapper{
                max-width: 1700px;
                position: relative;
                flex: 1;
            }

            @media screen and (max-width: 1410px) {
                padding: 0px 10% 0px 10%;
            }
    
            @media screen and (max-width: 1200px) {
                padding: 0px 5% 0px 5%;
            }

            .content {
                .trombi {
                    padding-bottom: 1rem;
                    a {
                        padding-right: .7rem;
        
                        img {
                            opacity: 0.9;
                            width: 45px;
                            border-radius: 100%;
                            transition: all 0.4s;
        
                            @media screen and (max-width: 768px) {
                                width: 35px;
                                line-height: 2rem;
                            }
                        }
                    }
        
                    @media screen and (max-width: 768px) {
                        padding-right: .6rem;
                    }
                }
        
                .title {
                    font: 43px/54px "gilroy-bold", sans-serif;
                    font-weight: 500;
                    color: #444;
            
                    @media screen and (max-width: 1410px) {
                        font-size: 40px;
                        line-height: 48px;
                    }
            
                    @media screen and (max-width: 1200px) {
                        font-size: 34px;
                        line-height: 45px;
                    }
        
                    @media screen and (max-width: 768px) {
                        font-size: 32px;
                        line-height: 42px;
                    }
        
                    @media screen and (max-width: 576px) {
                        font-size: 35px;
                        line-height: 50px;
                    }
                }
        
                .description{
        
                    font-size: 1.3rem;
                    line-height: 2.4rem;
        
                    @media screen and (max-width: 768px) {
                        font-size: 1.2rem;
                        line-height: 2.2rem;
                    }
        
                    @media screen and (max-width: 576px) {
                        font-size: 1.1rem;
                        line-height: 2rem;
                    }
                }
            }
        }
    }
}

.footer {
    padding-bottom: 60px;
    padding-top: 60px;
    background-color: #f2f4f9;

    .footer-details{
        font-size:13px;

        a {
            color: #191919;
        }

        &.about p{
            line-height: 1.8rem;
        }
    }
}

.service-details {
    position: relative;
    padding-top: 60px !important;
    padding-bottom: 40px !important;

    p {
        line-height: 32px;
    }

    img {
        width: 100%;
    }

    .mt-10 {
        margin-top:10rem;

        @media screen and (max-width: 576px) {
            margin-top:6rem;
        }
    }
}

.featured {
    min-height: 60vh;
    position: relative;
    padding-top: 60px !important;
    padding-bottom: 40px !important;

    @media screen and (max-width: 576px) {
        min-height: 75vh;
        padding-top: 5vh !important;
        padding-bottom: 5vh !important;
    }

    @media screen and (max-height: 900px) {
        min-height: 85vh;
        padding-bottom: 0px !important;
    }

    img.bg {
        position: absolute;
        bottom: 0px;
        right: 0px;
        height: 70%;
        z-index: -1;

        @media screen and (max-width: 1200px) {
            height: 400px;
        }

        @media screen and (max-width: 990px) {
            height: 380px;
            bottom: 50px;
        }

        @media screen and (max-width: 576px) {
            height: 290px;
            bottom: 10px;
            right: auto;
        }

        // @media screen and (max-height: 736px) {
        //     height: 500px;
        // }

        // @media screen and (max-height: 900px) {
        //     height: 600px;
        // }

        @media screen and (max-height: 900px) and (max-width: 1200px) {
            height: 400px !important;
            width: 100%;
        }

        @media screen and (max-height: 900px) and (max-width: 990px) {
            height: 320px  !important;
            width: 100%;
        }

        @media screen and (max-height: 900px) and (max-width: 576px) {
            height: 250px !important;
            width: 100%;
        }
    }

    .bgoverlay {
        background-image: url('/assets/images/bg/news.png');
        background-size: contain;
        position: absolute;
        width:100%;
        height: 100%;
        opacity:.03;
        left: 0;
        background-repeat: no-repeat;
        z-index: -1;
    }
}

.whoweare{
    position: relative;
    background: #243A51;
    color: white;
    padding: 4rem 0;
    text-align: center;

    h1 {
        font: 34px/48px "gilroy-bold", sans-serif;
        padding-bottom: 2rem;
        letter-spacing: .1rem;
        line-height: 44px;

        @media screen and (max-width: 990px) {
            padding-bottom: 1rem;
            font-size: 30px;
            line-height: 36px;
        }

        @media screen and (max-width: 576px) {
            font-size: 30px;
            line-height: 36px;
        }
    }

    .bg {
        background-image: url('/assets/images/bg/Clarence.png');
        background-size: contain;
        position: absolute;
        width:100%;
        height: 100%;
        opacity:.1;
        top:0;
    }

    p{
        font-size: 18px;
    }
}

.partners{
    img {
        &.max-w-100 {
            max-width: 100px;
        }

        &.max-w-200 {
            max-width: 200px;
        }
    }
}

.approach {
    padding-top: 20vh !important;
    padding-bottom: 25vh !important;
    height: 2000px;
    background-color:#243A51;

    @media screen and (max-width: 990px) {
        padding-top: 10vh !important;
        padding-bottom: 50vh !important;
    }

    @media screen and (max-width: 576px) {
        padding-top: 5vh !important;
    }

    .slides {
        position: sticky;
        top: 25vh;
        display: flex;
        flex-direction: row;
        color:#ffffff;
        
        @media screen and (max-width: 990px) {
            flex-direction: column;
            top: 10vh;
        }
    
        .labels {
            flex:3;
    
            h1 {
                font: 40px/54px "gilroy-bold", sans-serif;
                padding-bottom: 2rem;
                letter-spacing: .1rem;

                @media screen and (max-width: 990px) {
                    padding-bottom: 1rem;
                    font-size: 30px;
                }

                @media screen and (max-width: 576px) {
                    font-size: 30px;
                }
            }
    
            p {
                font-size: 1.2rem;
                line-height: 2rem;

                @media screen and (max-width: 990px) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }
    
            .links {
                padding-top: 2rem;
                display: flex;
                flex-direction: column;

                @media screen and (max-width: 990px) {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    flex-direction: row;
                }

                .link {
                    padding: .8rem 0;
                    font-size: 1.3rem;
                    font-weight: 600;

                    @media screen and (max-width: 990px) {
                        padding: .3rem .5rem;
                        font-size: 1rem;
                    }
        
                    i {
                        padding-right: .8rem;
                        @media screen and (max-width: 990px) {
                            display: none;
                        }
                    }
    
                    &.active {
                        color:#cc0e63;
                    }
                }
            }
        }
    
        .illustrations {
            flex:4;
            position: relative;
            padding-left: 2rem;

            @media screen and (max-width: 990px) {
                padding-left: 0;
            }

            .illustration {
                position: absolute;
                display:flex;
                visibility: hidden;
                opacity: 0;
                flex-direction: column;
                align-items: center;
                transition: all 1000ms cubic-bezier(0, 1.1, 1, 0.975);
                transform: translateY(-50px);
    
    
                &.active {
                    visibility: visible;
                    opacity: 1;
                    transition: all 2000ms cubic-bezier(0, 1.1, 1, 0.975);
                    transition-delay: 0.2s;
                    transform: translateY(0px);
                }
    
                img {
                    width: 100%;

                    @media screen and (max-width: 990px) {
                        width: 80%;
                        padding-top: 1rem;
                    }
                }
    
                p {
                    padding-top: 3rem;
                    font-size: 1.2rem;
                    line-height: 2rem;
                    text-align: center;

                    @media screen and (max-width: 990px) {
                        padding-top: 2rem;
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }
}



.customers {
    position: relative;
    background: #7594ec30;

    h2 {
        font: 1.2rem "gilroy-bold", sans-serif;
        text-align: center;
        line-height: 30px;
        margin-top: 3rem;
    }
    
    .bg {
        background-image: url('/assets/images/bg/worktogether.png');
        background-size: contain;
        position: absolute;
        width:100%;
        height: 100%;
        opacity:.05;
        top:0;
        z-index: -1;
    }

    .logos {
        display: flex;
        flex-direction: row;
        padding: 2rem 0;
        justify-content: center;
        overflow: hidden;

        .customer-logo {
            height: 50px;
            justify-content: center;
            display: flex;
            padding: 0 2.5rem;

            &.o7{
                opacity: .7;
            }
    
            img {
                height: 100%;
            }
        }
    }

    .collaboration {
        text-align: center;
        padding: 2rem 0 3rem 0;

        h1 {
            font: 30px "gilroy-bold", sans-serif;
            padding-bottom: 1rem;
            letter-spacing: .1rem;
        }

        p {
            padding-bottom: 2rem;
        }

    }
}

.services {
    background-color: #f7f8fb;
    padding: 4rem 0;

    h1 {
        font: 40px/54px "gilroy-bold", sans-serif;
        padding-bottom: 3rem;
        letter-spacing: .1rem;
        line-height: 46px;

        @media screen and (max-width: 990px) {
            padding-bottom: 1rem;
            font-size: 30px;
            line-height: 36px;
        }

        @media screen and (max-width: 576px) {
            font-size: 30px;
            line-height: 36px;
        }
    }

    .service {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        h2 {
            font: 1.1rem "gilroy-bold", sans-serif;
        }
    
        .icon{
            height: 40px;
            width: 40px;
            margin-bottom: 20px;
        
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.service-details {
    padding-top:175px;
    
    h2{
        font: 30px/32px "gilroy-bold", sans-serif;
        color: #444;
    }

    h3{
        color: #607d9c!important;
        letter-spacing: .1em!important;
        text-transform: uppercase!important;
        font-size: 12px;
        line-height: 18px;
        font-weight: 700!important;
    }

    .img {
        img {
            width:100%;
        }
    }

    .tags {
        margin: 30px 30px 40px 0;
        font-size: 20px;
    }
}

.contact-us{
    textarea{
        height: 250px;
    }
}

.team {
    background-color: #f7f8fb;

    h1 {
        font: 40px/54px "gilroy-bold", sans-serif;
        padding-bottom: 3rem;
        letter-spacing: .1rem;
    }

    .profil {
        padding-bottom:2rem;
        text-align: center;

        img {
            max-width: 200px;
            padding-bottom:1rem;
        }

        .name {
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.4em;
        }

        .description {
            margin-top: 4px;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.4em;
            color: #aaa;
        }
    }
}

.logo {
    height: 40px;

    @media screen and (max-width: 576px) {
        height: 35px
    }
}

